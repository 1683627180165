import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {Button, ButtonType, PageContainer, PageHeader} from "@reapptor-apps/reapptor-react-components";
import ReactMarkdown from "react-markdown";
import {OccupationalHealthData, OccupationalHealthResponse} from "@/models/cms/OccupationalHealthResponse";
import styles from "./OccupationalHealth.module.scss";

import FenixAppController from "@/pages/FenixAppController";
import Localizer from "@/localization/Localizer";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";

interface IOccupationalHealthProps {
}

export interface IOccupationalHealthParams {}

interface IOccupationalHealthState {
    data: OccupationalHealthData | null;
}

export default class OccupationalHealth extends AnonymousPage<IOccupationalHealthProps, IOccupationalHealthState> {
    state: IOccupationalHealthState = {
        data: null
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const response: OccupationalHealthResponse = await this.getAsync(
            "/api/Application/GetOccupationalHealthData"
        );

        await this.setState({data: response.data});
    }

    public render(): React.ReactNode {

        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={Localizer.occupationalHealthPageTitle}/>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {this.state.data && (
                            <>
                                <h2>{this.state.data.attributes.title}</h2>
                                <ReactMarkdown>{this.state.data.attributes.content}</ReactMarkdown>

                                <div className={styles.occupationalHealthTeamRosterTitle}>
                                    <h2>{Localizer.occupationalHealthPageOccupationalHealthTeamRoster}</h2>
                                </div>
                                <div className={styles.occupationalHealthTeam}>
                                    {this.state.data.attributes.doctors &&
                                        <>
                                            <div className={styles.doctorsContainer}>
                                                {this.state.data.attributes.doctors.data.map((field) => {
                                                        return (
                                                            <div className={styles.doctor} key={`doctor_${field.id}`}
                                                                 onClick={async () => {PageRouteProvider.redirectAsync(PageDefinitions.doctor.route({params: {selectedDoctor: field.attributes.externalId}}))}}>
                                                                <img src={field?.attributes?.image?.data?.attributes?.url ?? FenixAppController.defaultDoctorImage()} className={styles.doctorImage}/>
                                                                <div className={styles.titleContainer}>
                                                                    <h3>{field.attributes.firstName + " " + field.attributes.lastName}</h3>
                                                                    <center>{field.attributes.title}</center>
                                                                </div>
                                                            </div>
                                                        )

                                                    })}
                                            </div>
                                        </>}
                                </div>
                                <div className={styles.appointmentButtonDiv}>
                                <Button label={Localizer.homePageHeaderOccupationalHealthBooking}
                                        icon={"hospital"}
                                        type={ButtonType.Orange}

                                        onClick={async () => FenixAppController.openOccupationalAppointments()}
                                />
                                </div>
                                {this.state.data.attributes.salesPitchContent && (
                                    <div className={styles.salesPitch}>
                                        <h2>{this.state.data.attributes.salesPitchTitle}</h2>
                                        <div className={styles.salesPitchContent}>
                                            <ReactMarkdown>{this.state.data.attributes.salesPitchContent}</ReactMarkdown>
                                        </div>

                                        <div className={styles.salesPitchContainer}>
                                            <div className={styles.salesPitchImgContainer}>
                                                <img src={this.state.data.attributes.salesPitchImage.data.attributes.url}
                                                     className={styles.salesGuy}/>
                                            </div>
                                            <div className={styles.salesPitchContactInfo}>
                                                <div style={{margin: "auto"}}>
                                                    <ReactMarkdown>{this.state.data.attributes.salesPitchContactInfo}</ReactMarkdown>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </PageContainer>
        );
    }
}


/*{this.state.data.attributes.salesPitchContent && (
    <div>
        <h2>{this.state.data.attributes.salesPitchTitle}</h2>
        <div className={"row"}>
            <div className={"col-md-3"}>
                <img src={`https://test-fenix.placeholder.fi/strapi-images/Riivari_Jere_2506lahi_Studio_Lindell_608ffe5bfe.jpg`}
                     className={styles.salesGuy}/>
            </div>
            <div className={"col-md-9"}>
                <ReactMarkdown>{this.state.data.attributes.salesPitchContent}</ReactMarkdown>
            </div>
        </div>
    </div>
)}*/
