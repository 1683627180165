import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {List, PageContainer, PageHeader, PageRow, SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import ReactMarkdown from "react-markdown";
import styles from "./Company.module.scss";
import Localizer from "@/localization/Localizer";

interface ICompanyProps {}

export interface ICompanyParams {}

interface ICompanyState {
    companies: DynamicPageData[];

    selectedCompany: DynamicPageData | null;
}

export default class Company extends AnonymousPage<ICompanyProps, ICompanyState> {
    state: ICompanyState = {
        companies: [],
        selectedCompany: null
    };

    private readonly _listRef: React.RefObject<List<DynamicPageData>> = React.createRef();

    public toMedicalFieldListItem(item: DynamicPageData): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id.toString();
        selectedItem.text = item.attributes!.name;
        return selectedItem;
    }

    private async selectMedicalField(value: DynamicPageData | null): Promise<void> {
        await this.setState({selectedCompany: value});
    }

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        const response: DynamicPageResponse = await this.getAsync(
            "/api/Application/GetDynamicPageContent?contentType=companies&populate=*"
        );
        if (response.data) {
            await this.setState({companies: response.data, selectedCompany: response.data[0]});
        }
    }

    private get selectedClinic(): DynamicPageData | null {
        return this.state.selectedCompany;
    }

    private getIcon(item: DynamicPageData): string {
        if (item?.attributes?.icon?.data?.attributes?.url) {
            return item.attributes.icon.data.attributes?.url;
        }

        return "";
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader className={styles.pageHeader} title={Localizer.companyPageTitle} />

                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        <div className="row m-0 p-0">
                            <div className={"col-md-4"} style={{paddingTop: "3rem"}}>
                                {this.state.companies &&
                                    this.state.companies.map((item) => {
                                        return (
                                            <div>
                                                <div
                                                    className={
                                                        this.state.selectedCompany == item
                                                            ? this.css(styles.selected, styles.listItem)
                                                            : styles.listItem
                                                    }
                                                    onClick={async () => await this.setState({selectedCompany: item})}
                                                >
                                                    <img src={this.getIcon(item)} height={40} width={40} />
                                                    <div>{item.attributes.name}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>

                            <div className={"col-md-8"} style={{paddingTop: "3rem"}}>
                                {this.state.selectedCompany && (
                                    <ReactMarkdown>{this.state.selectedCompany.attributes.content}</ReactMarkdown>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
