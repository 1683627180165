import React from "react";
import {
    Button,
    ButtonType,
    Icon,
    IconSize,
    IStringInputModel,
    Modal,
    PageContainer,
    PageHeader,
    SelectListItem
} from "@reapptor-apps/reapptor-react-components";
import AnonymousPage from "@/models/base/AnonymousPage";
import {
    AppointmentBookingServicePointFilter,
    AppointmentBookingServicePointFilterOnChange
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointFilter/AppointmentBookingServicePointFilter";
import {
    AppointmentBookingServicePointList,
    AppointmentBookingServicePointListPagination,
    PossibleAppointment
} from "@/pages/AppointmentBooking/AppointmentBookingServicePointList/AppointmentBookingServicePointList";
import styles from "./AppointmentBooking.module.scss";
import GetAppointmentsInternalRequest from "@/models/server/requests/GetAppointmentsInternalRequest";
import {AppointmentFilterType} from "@/models/Enums";
import FenixAppController from "@/pages/FenixAppController";
import ReserveTimeModal from "@/components/ReserveTimeModal/ReserveTimeModal";
import {IDoctor} from "@/models/interfaces/IDoctor";
import {IMedicalField} from "@/models/interfaces/IMedicalField";
import {INameExternalId} from "@/models/interfaces/INameExternalId";
import CancelAppointmentModal from "@/components/CancelAppointmentModal/CancelAppointmentModal";
import {ApiProvider, BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import {ILocation} from "@/models/interfaces/ILocation";
import Localizer from "@/localization/Localizer";
import moment from "moment-timezone";
import {DateTime} from "luxon";
import {DynamicPageData, DynamicPageResponse} from "@/models/cms/DynamicPageResponse";
import {LocationsData} from "@/components/Footer/Footer";

export interface IAppointmentBookingParams extends BasePageParameters {
    selectedLocation?: string | undefined;
    selectedDoctor?: string | undefined;
    selectedCare?: string | undefined;
    selectedDate?: string | null;
    occupationalHealth?: string | undefined;
}

interface IAppointmentBookingProps {
}

interface IAppointmentBookingState {
    locationsData: DynamicPageData[];
    availableServices: IMedicalField[];

    availableDoctors: IDoctor[];

    availableLocations: INameExternalId[];

    filterValue: AppointmentBookingServicePointFilterOnChange | null;
    /**
     * Implemented test pagination system
     * TODO: Replace when pagination implemented
     */
    pagedPossibleAppointmentData: AppointmentBookingServicePointListPagination<PossibleAppointment>;

    filterData: IFilterData | null;

    firstFilterIndex: AppointmentFilterType;

    secondFilterIndex: AppointmentFilterType;

    defaultLocation: INameExternalId | null;

    defaultSpecialist: IDoctor | null;

    defaultMedicalField: IMedicalField | null;

    defaultDate: Date | null;

    paramsProcessed: boolean;

    timeBookingForOccupationalHealth: boolean;
    showOccupationalHealthInfoBox: boolean;
}

interface IFilterData {
    medicalFields: IMedicalField[];

    doctors: IDoctor[];

    locations: INameExternalId[];
}

export default class AppointmentBooking extends AnonymousPage<IAppointmentBookingProps, IAppointmentBookingState> {
    public firstname: IStringInputModel = {value: ""};
    public lastname: IStringInputModel = {value: ""};
    public ssn: IStringInputModel = {value: ""};

    public _loginModal: React.RefObject<Modal> = React.createRef();
    public _appointmentModal: React.RefObject<Modal> = React.createRef();

    private readonly _reserveTimeModal: React.RefObject<ReserveTimeModal> = React.createRef();
    private readonly _cancelAppointmentModal: React.RefObject<CancelAppointmentModal> = React.createRef();
    public formRef: React.RefObject<any> = React.createRef();

    state: IAppointmentBookingState = {
        locationsData: [],
        availableServices: [],
        availableLocations: [],
        availableDoctors: [],
        filterValue: null,
        filterData: null,
        firstFilterIndex: AppointmentFilterType.None,
        secondFilterIndex: AppointmentFilterType.None,
        defaultLocation: null,
        defaultDate: null,
        paramsProcessed: false,
        timeBookingForOccupationalHealth: false,
        defaultSpecialist: null,
        defaultMedicalField: null,
        showOccupationalHealthInfoBox: true,
        pagedPossibleAppointmentData: {
            loading: false,
            pageIndex: 0,
            pageSize: 5,
            totalCount: 25,
            items: []
        }
    };

    public getTitle(): string {
        return Localizer.appointmentPageTitle;
    }

    private async initializeFiltersDataAsync(): Promise<void> {
        const params = this.parameters as IAppointmentBookingParams as IAppointmentBookingParams | null;

        if (!this.state.paramsProcessed && (params?.occupationalHealth && params?.occupationalHealth !== 'false')) {
            await this.setState({timeBookingForOccupationalHealth: true});
        }

        const dynamicData: IFilterData = await this.postAsync(
            "/api/Application/GetFilterData",
            this.state.timeBookingForOccupationalHealth
        );

        if (params) {
            let dateValue = params.selectedDate ? moment(params.selectedDate).toDate() : null;
            const matching: INameExternalId | null = dynamicData.locations.filter((item) => item.id === params.selectedLocation)[0];
            const doctor: IDoctor | null = dynamicData.doctors.filter((item) => item.id === params.selectedDoctor)[0];
            const care: IMedicalField | null = dynamicData.medicalFields.filter((item) => item.id === params.selectedCare)[0];

            
            await this.setState({
                defaultLocation: matching,
                defaultDate: dateValue,
                defaultSpecialist: doctor,
                defaultMedicalField: care
            });
        }

        await this.setState({filterData: dynamicData});

        await this.setState({paramsProcessed: true});

        await this.setDefaultFiltersAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        window.scrollTo(0, 0);

        const locationsData: DynamicPageResponse = await this.getAsync("/api/Application/GetDynamicPageContent?contentType=locations&deepPopulate=openingHours&sort=externalId");
        if (locationsData && locationsData?.data) {
            await this.setState({locationsData: locationsData.data})
        }

        await this.initializeFiltersDataAsync();
    }

    async closeInfoBox(): Promise<void> {
        await this.setState({showOccupationalHealthInfoBox: false});
    }

    public async setDefaultFiltersAsync(): Promise<void> {
        if (this.state.filterData) {
            await this.setState({
                availableServices: this.state.filterData?.medicalFields ?? [],
                availableDoctors: this.state.filterData?.doctors ?? [],
                availableLocations: this.state.filterData?.locations ?? []
            });
        }
    }

    public toSelectListItem(item: INameExternalId | IDoctor | IMedicalField): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        return selectedItem;
    }

    private async fetchData(filterValue: AppointmentBookingServicePointFilterOnChange | null) {
        const specialist: IDoctor = filterValue?.specialist as unknown as IDoctor;
        const location: ILocation | null = filterValue?.location as any as ILocation;
        const medicalField: IMedicalField = filterValue?.medicalField as unknown as IMedicalField;
        
        const timeRangeStart = DateTime.fromJSDate(filterValue!.timeRangeStart).setZone('Europe/Helsinki')
        const offset = timeRangeStart.isInDST ? 180 : 120;
        
        const request: GetAppointmentsInternalRequest = {
            dateRangeEnd: DateTime.fromJSDate(filterValue!.dateRangeEnd).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC(offset,{keepLocalTime: true}).toJSDate(),
            timeRangeStart: DateTime.fromJSDate(filterValue!.timeRangeStart).toUTC(offset, {keepLocalTime: true}).toJSDate(),
            timeRangeEnd: DateTime.fromJSDate(filterValue!.timeRangeEnd).toUTC(offset, {keepLocalTime: true}).toJSDate(),
            dateRangeStart: DateTime.fromJSDate(filterValue!.dateRangeStart).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC(offset,{keepLocalTime: true}).toJSDate(),
            selectedService: medicalField?.id ? [medicalField.id] : filterValue?.medicalField ? [filterValue.medicalField.id] : [],
            selectedPractitioners: specialist?.id ? [specialist.id] : filterValue?.specialist ? [filterValue.specialist.id] : [],
            selectedClinics: location?.id ? [location.id] : filterValue?.location ? [filterValue.location.id] : [],
            occupationalHealth: this.state.timeBookingForOccupationalHealth
        };

        const data: PossibleAppointment[] = await this.postAsync("/api/Application/GetAvailableAppointments", request);
        const pagedThing: AppointmentBookingServicePointListPagination<PossibleAppointment> = {
            loading: false,
            pageIndex: 0,
            items: data,
            pageSize: 5,
            totalCount: data.length
        };

        await this.setState({pagedPossibleAppointmentData: pagedThing});
    }

    private async reserveTimeForOccupationalHealthAsync(): Promise<void> {
        await FenixAppController.openOccupationalAppointments()
    }

    private async reserveTimeForNormalAppointmentsAsync(): Promise<void> {
        await FenixAppController.openAppointmentsPage();
    }

    private openRemoteReception() {
        window.location.href = "https://fenix.movendosplatform.com/#login";
        return Promise.resolve(undefined);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth className={styles.pageContainer}>
                <PageHeader title={Localizer.topNavAppointmentBooking} className={styles.pageHeader}></PageHeader>
                <div className={styles.pageContent}>
                    <div className={styles.fluidContent}>
                        {this.state.showOccupationalHealthInfoBox &&
                            <div className={styles.occupationalHealthInfoBox}>
                                <Icon name={"times"} size={IconSize.Large} style={2} customStyle={{position: "absolute", top: "0.75rem", right: "0.75rem", cursor: "pointer"}} onClick={() => this.closeInfoBox()}/>
                                <div className={styles.infoBoxTextDiv}>
                                    <div>
                                        <strong>{Localizer.appointmentPageOccupationalHealthInfo}</strong>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={styles.reserveTimeCard}>
                            <div className={styles.cardHeader}>
                                <Icon name="clock" style={2} size={IconSize.Large}/>
                                <span>{Localizer.buttonBookAppointment}</span>
                            </div>
                            <div className={styles.reserveTimeNav}>
                                <Button
                                    label={Localizer.homePageHeaderLocation}
                                    type={!this.state.timeBookingForOccupationalHealth ? ButtonType.Primary : ButtonType.Default}
                                    onClick={async () => await this.reserveTimeForNormalAppointmentsAsync()}
                                    icon={"hospital"}
                                    className={styles.appointmentButton}
                                />
                                <Button
                                    label={Localizer.homePageHeaderOccupationalHealthBooking}
                                    icon={"hospital"}
                                    type={this.state.timeBookingForOccupationalHealth ? ButtonType.Primary : ButtonType.Default}
                                    onClick={async () => await this.reserveTimeForOccupationalHealthAsync()}
                                    className={styles.appointmentButton}
                                />
                                <Button
                                    label={Localizer.homePageHeaderRemoteBooking}
                                    type={ButtonType.Default}
                                    icon={"laptop-medical"}
                                    onClick={() => this.openRemoteReception()}
                                    className={styles.appointmentButton}
                                />
                            </div>

                            {this.state.paramsProcessed && (
                                <AppointmentBookingServicePointFilter
                                    defaultDate={this.state.defaultDate}
                                    defaultLocation={this.state.defaultLocation}
                                    defaultSpecialist={this.state.defaultSpecialist}
                                    defaultMedicalField={this.state.defaultMedicalField}
                                    medicalFieldList={this.state.availableServices}
                                    specialistList={this.state.availableDoctors}
                                    locationList={this.state.availableLocations}
                                    medicalFieldToListItem={(item) => this.toSelectListItem(item)}
                                    locationToListItem={(item) => this.toSelectListItem(item)}
                                    specialistToListItem={(item) => this.toSelectListItem(item)}
                                    listItemToLocation={(item) => item.ref}
                                    listItemToSpecialist={(item) => item.ref}
                                    listItemToTypeOfCare={(item) => item.ref}
                                    onChange={async (filterValue) => await this.fetchData(filterValue)}
                                />
                            )}

                            <div className={styles.search}>
                                <Button label={Localizer.genericSearch} type={ButtonType.Primary}/>
                            </div>
                            <AppointmentBookingServicePointList
                                pagedData={this.state.pagedPossibleAppointmentData}
                                onShowMoreClick={async () => {
                                    //  Test pagination setup
                                    this.setState({
                                        pagedPossibleAppointmentData: {
                                            ...this.state.pagedPossibleAppointmentData,
                                            loading: true
                                        }
                                    });
                                    //  Simulate data fetching.
                                    setTimeout(() => {
                                        this.setState({
                                            pagedPossibleAppointmentData: {
                                                ...this.state.pagedPossibleAppointmentData,
                                                pageIndex: this.state.pagedPossibleAppointmentData.pageIndex + 1,
                                                items: [...this.state.pagedPossibleAppointmentData.items],
                                                loading: false
                                            }
                                        });
                                    }, 1000);
                                }}
                                onReserveTimeClick={async (possibleAppointment: PossibleAppointment) => {
                                    this._reserveTimeModal.current?.openAsync(
                                        possibleAppointment,
                                        this.state.timeBookingForOccupationalHealth,
                                      this.state.locationsData
                                    );
                                }}
                            ></AppointmentBookingServicePointList>
                        </div>
                        <div className={styles.cancelAppointmentContainer}>
                            <h5>{Localizer.appointmentPageWantToCancelAppointment}</h5>
                            <Button
                              label={Localizer.appointmentPageAppointmentCanceling}
                              type={ButtonType.Primary}
                              className={styles.appointmentButton}
                              onClick={async () => await this._cancelAppointmentModal.current?.openAsync()}
                            />
                        </div>
                    </div>
                </div>

                <ReserveTimeModal
                    ref={this._reserveTimeModal}
                    onTimeReserved={(sender, time: PossibleAppointment) => FenixAppController.onReserveTime(time)}
                />

                <CancelAppointmentModal
                    ref={this._cancelAppointmentModal}
                    onTimeReserved={(sender, time: PossibleAppointment) => FenixAppController.onReserveTime(time)}
                />
            </PageContainer>
        );
    }
}
